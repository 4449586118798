<template>
  <a-spin class="max-width" :spinning="false" size="large" tip="加载中" style="min-height: 650px">
    <a-row type="flex" style="margin: 20px auto; max-width: 1600px;">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="'/'">
            <a-icon type="home" />首页
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="'/user'">个人中心</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>评价管理</a-breadcrumb-item>
      </a-breadcrumb>
    </a-row>
    <div id="components-form-demo-advanced-search" style="text-align: center">
      <a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="产品编号">
              <a-input placeholder="按编号精确匹配" v-model="params.sn_product_info" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="评价内容">
              <a-input placeholder="按内容模糊匹配" v-model="params.content" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="评分">
              <a-input placeholder="评分等级，仅限 1 - 10" v-model="params.rank" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="排序">
              <a-select default-value="0" v-model="params.order">
                <a-select-option value="0">最新评价</a-select-option>
                <a-select-option value="1">最早评价</a-select-option>
                <a-select-option value="2">评分最低</a-select-option>
                <a-select-option value="3">评分最高</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" html-type="submit">查询</a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="resetParams">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="search-result-list">
        <a-table
          :columns="[
            { title: '产品编号', dataIndex: 'sn_product_info'  },
            { title: '订单产品', dataIndex: 'name', scopedSlots: { customRender: 'name'} },
            { title: '评分', dataIndex: 'rank', scopedSlots: { customRender: 'rank' } },
            { title: '评价内容', dataIndex: 'content' },
            { title: '详情', scopedSlots: { customRender: 'url' } },
            { title: '操作', scopedSlots: { customRender: 'action' } },
          ]"
          :row-key="(record) => record.id_product_comment"
          :data-source="data"
          :loading="spinning"
          :pagination="false"
          style="margin: 20px"
        >
          <!-- 产品 -->
          <span slot="name" slot-scope="text, record">
            <router-link :to="{ name: 'product', query: { sn: record.sn_product_info }}">
              <a class="theme">{{ text }}</a>
            </router-link>
          </span>
          <!-- 评分 -->
          <span slot="rank" slot-scope="text, record">
            <a-rate v-model="record.rank" disabled allowHalf />
          </span>
          <!-- 查看详情 -->
          <span slot="url" slot-scope="text, record">
            <a-button type="link" style="padding: 0" @click="showComment(record)">查看详情</a-button>
          </span>
          <!-- 删除 -->
          <span slot="action" slot-scope="text, record">
            <a-button type="link" style="padding: 0" @click="deleteComment(record)">
              <a-icon type="delete" />删除
            </a-button>
          </span>
        </a-table>
        <Pagination
          method="getAllCommentForFranchisee"
          ref="Pagination"
          :ok="setData"
          :loading="() => spinning = true"
          :params="params"
        />
      </div>
    </div>
    <!-- 评价 -->
    <a-modal v-model="modalName.comment" title="评价详情" :footer="null">
      <a-skeleton active :loading="isLoadComment" :paragraph="{ rows: 8 }">
        <div class="comment">
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="评分" style="margin: 20px 0" required>
              <a-rate v-model="modalData.rank" disabled allowHalf />
              <span class="ant-rate-text">{{ rateText[~~(modalData.rank - .5)] }}</span>
            </a-form-item>
            <a-form-item label="评价" required>
              <a-input type="textarea" disabled style="height: 100px" v-model="modalData.content" />
            </a-form-item>
            <a-form-item label="图片" v-if="modalData.url !== []">
              <img v-image-preview v-for="url in modalData.url" :key="url" :src="url" />
            </a-form-item>
          </a-form>
        </div>
      </a-skeleton>
    </a-modal>
  </a-spin>
</template>
<script>
import Pagination from '@/component/Pagination.vue'
export default {
  data() {
    return {
      spinning: false,
      expand: false,
      data: [],
      params: this.resetParams(),
      isLoadComment: false, // 是否加载评论详情
      modalName: this.hideModal(), // 弹框名称
      modalData: {}, // 评论弹框内数据
      rateText: ['糟糕', '失望', '一般', '还行', '满意'],
      form: this.$form.createForm(this, { name: 'advanced_search' })
    }
  },

  methods: {
    setData(data) {
      data.map((e) => (e.rank /= 2))
      this.spinning = false
      this.data = data
    },
    deleteComment(record) {
      this.$confirm({
        title: '删除评论',
        content: '删除后评论将不再显示',
        onOk: () => {
          this.spinning = true
          this.post('deleteCommentForFranchisee', {
            id_product_comment: record.id_product_comment
          })
            .then(() => {
              this.spinning = false
              this.$refs.Pagination.toPage(1)
              this.$message.success('评论删除成功')
            })
            .catch((err) => {
              this.spinning = false
              this.$message.error(err.msg)
            })
        }
      })
    },
    handleSearch(e) {
      e.preventDefault()
      this.$refs.Pagination.toPage(1)
    },
    resetParams() {
      return (this.params = {
        sn_product_info: null,
        content: null,
        rank: null,
        order: '0'
      })
    },
    showComment(record) {
      this.modalData = Object.assign({}, record)
      this.modalName.comment = true // 显示评论弹框
      this.isLoadComment = true // 显示骨架屏
      this.get('getAllCommentForFranchisee', {
        id_product_comment: record.id_product_comment
      })
        .then((res) => {
          this.isLoadComment = false
          this.modalData = res.data[0]
          this.modalData.rank /= 2
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.hideModal()
          this.isLoadComment = false
        })
    },
    hideModal() {
      return (this.modalName = {
        comment: false
      })
    }
  },

  components: {
    Pagination
  }
}
</script>

<style scoped>
.max-width {
  max-width: 1600px;
  padding: 0 50px;
}
.comment img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.comment img:nth-last-child(3) {
  margin-right: 0;
}
</style>
